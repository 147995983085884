export const STORE_PRODUCT = 'STORE_PRODUCT';
export const STORE_PRODUCTS = 'STORE_PRODUCTS';
export const STORE_PREFERENCE = 'STORE_PREFERENCE';
export const STORE_PREFERENCES = 'STORE_PREFERENCES';
export const STORE_PREFERENCE_VALUE = 'STORE_PREFERENCE_VALUE';
export const STORE_FUTURE_PREFERENCE_VALUE = 'STORE_FUTURE_PREFERENCE_VALUE';
export const STORE_PREFERENCE_VALUES = 'STORE_PREFERENCE_VALUES';
export const STORE_PREFERENCE_VALUE_LIST = 'STORE_PREFERENCE_VALUE_LIST';
export const STORE_PREFERENCE_VALUE_CHANGES = 'STORE_PREFERENCE_VALUE_CHANGES';
export const ADD_TO_PREFERENCE_VALUE_LIST = 'ADD_TO_PREFERENCE_VALUE_LIST';
export const STORE_PREFERENCE_VALUE_LIST_INFO = 'STORE_PREFERENCE_VALUE_LIST_INFO';
export const RESET = 'RESET';
export const RESET_PRODUCT = 'RESET_PRODUCT';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const RESET_PREFERENCE = 'RESET_PREFERENCE';
export const RESET_PREFERENCES = 'RESET_PREFERENCES';
export const RESET_PREFERENCE_VALUE = 'RESET_PREFERENCE_VALUE';
export const RESET_PREFERENCE_VALUES = 'RESET_PREFERENCE_VALUES';
export const STORE_ROLES = 'STORE_ROLES';
export const RESET_ROLES = 'RESET_ROLES';
export const STORE_PRIVILEGES = 'STORE_PRIVILEGES';
export const RESET_PRIVILEGES = 'RESET_PRIVILEGES';
export const STORE_PREFERENCETYPES = 'STORE_PREFERENCETYPES';
export const RESET_PREFERENCETYPES = 'RESET_PREFERENCETYPES';
