import { computed } from 'vue';
import { useStore } from 'vuex';

import {
  getPreferences,
} from '@/services/system-management';

/**
 * This file is deprecated and has no use whatsoever.
 */

// eslint-disable-next-line import/prefer-default-export
export const usePreferences = () => {
  const store = useStore();

  const preference = computed(() => store.getters['systemManagement/preference']);
  const preferences = computed(() => store.getters['systemManagement/preferences']);

  const storePreference = (pref) => store.dispatch('systemManagement/storePreference', pref);
  const storePreferences = (prefs) => store.dispatch('systemManagement/storePreferences', prefs);

  const resetPreference = () => store.dispatch('systemManagement/resetPreference');
  const resetPreferences = () => store.dispatch('systemManagement/resetPreferences');

  const fetchPreferences = async ({ productCode }) => {
    try {
      const payload = await getPreferences({ productCode });
      storePreferences(payload.preferences);
      return true;
    } catch (err) {
      console.log('Error in system fetchPreferences:', err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  const hasPreferences = async ({ productCode }) => {
    try {
      const payload = await getPreferences({ productCode });
      return payload.preferences?.length > 0;
    } catch (err) {
      console.error('Error when getting preferences for product.', err);
      return false;
    }
  };

  return {
    preference,
    preferences,
    hasPreferences,
    fetchPreferences,
    storePreference,
    storePreferences,
    resetPreference,
    resetPreferences,
  };
};
