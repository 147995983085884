/* eslint-disable import/prefer-default-export */
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useUserManagementStore } from '@/store/user-management';
import { useRoute } from 'vue-router';
import { useAuth0 } from '@auth0/auth0-vue';

const defaultState = {
  actor: undefined,
  notAuthorizedProductCodes: [],
  product: undefined,
  productsActorOnlyCanDelegate: [],
  authServiceAccessToken: undefined,
  authServiceRefreshToken: undefined,
  authServiceProducts: [],
  canUsePreferencesMenu: false,
};

export const useActorStore = defineStore('actor', () => {
  const {
    getUser,
  } = useUserManagementStore();

  const auth0 = useAuth0();

  const route = useRoute();

  const initialState = () => cloneDeep(defaultState);

  const actor = ref(defaultState.actor);
  const notAuthorizedProductCodes = ref(defaultState.notAuthorizedProductCodes);
  const product = ref(defaultState.product);
  const productsActorOnlyCanDelegate = ref(defaultState.productsActorOnlyCanDelegate);
  const authServiceAccessToken = ref(defaultState.authServiceAccessToken);
  const authServiceRefreshToken = ref(defaultState.authServiceRefreshToken);
  const authServiceProducts = ref(defaultState.authServiceProducts);

  const canUsePreferencesMenu = ref(defaultState.canUsePreferencesMenu);

  const mainTabs = computed(() => {
    let list;
    list = [
      { name: 'Preferences', disabled: true, tooltip: 'No product selected' },
      { name: 'Users', disabled: true, tooltip: 'No product selected' },
      { name: 'Product', disabled: true, tooltip: 'No product selected' },
      { name: 'Hierarchies', disabled: true, tooltip: 'Not available' },
      { name: 'Management', disabled: true, tooltip: 'Not available' },
    ];

    if (!route.query.productCode || route.query.productCode === 'All products') {
      return [
        { name: 'Preferences', disabled: true, tooltip: 'No product selected' },
        { name: 'Users' },
        { name: 'Product', disabled: true, tooltip: 'No product selected' },
        { name: 'Hierarchies', disabled: true, tooltip: 'Not available' },
        { name: 'Management', disabled: true, tooltip: 'Not available' },
      ];
    }
    list = [
      { name: 'Preferences' },
      { name: 'Users' },
      { name: 'Product' },
      { name: 'Hierarchies', disabled: true, tooltip: 'Not available' },
      { name: 'Management', disabled: true, tooltip: 'Not available' },
    ];

    if (route.query.productCode) {
      if (!canUsePreferencesMenu.value) {
        list = [
          { name: 'Preferences', disabled: true, tooltip: 'This product has no preferences' },
          { name: 'Users' },
          { name: 'Product' },
          { name: 'Hierarchies', disabled: true, tooltip: 'Not available' },
          { name: 'Management', disabled: true, tooltip: 'Not available' },
        ];
      }
      // If the selected product is only available for User tab
      if (productsActorOnlyCanDelegate.value.includes(product.value)) {
        list = [
          { name: 'Preferences', disabled: true, tooltip: 'View rights missing' },
          { name: 'Users' },
          { name: 'Product', disabled: true, tooltip: 'View rights missing' },
          { name: 'Hierarchies', disabled: true, tooltip: 'Not available' },
          { name: 'Management', disabled: true, tooltip: 'Not available' },
        ];
      }
    } else {
      list = [
        { name: 'Preferences', disabled: true, tooltip: 'No product selected' },
        { name: 'Users', disabled: true, tooltip: 'No product selected' },
        { name: 'Product', disabled: true, tooltip: 'No product selected' },
        { name: 'Hierarchies', disabled: true, tooltip: 'Not available' },
        { name: 'Management', disabled: true, tooltip: 'Not available' },
      ];
    }
    return list;
  });

  /**
  * @deprecated Use the state param directly instead of the store function.
  */
  const storeProduct = (prod) => {
    product.value = prod;
  };

  const resetProduct = () => {
    product.value = initialState().product;
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const loadActor = async () => {
    // eslint-disable-next-line no-await-in-loop
    while (auth0.isLoading.value) { await delay(500); }
    if (!actor.value) {
      const state = auth0;
      const response = await getUser({ userId: state.user.value['https://accounts.ikea.com/networkid'] });
      actor.value = response.user;
      notAuthorizedProductCodes.value = response.notAuthorizedProductCodes;
    }
  };

  return {
    actor,
    notAuthorizedProductCodes,
    product,
    loadActor,
    storeProduct,
    resetProduct,
    authServiceAccessToken,
    authServiceRefreshToken,
    authServiceProducts,
    /** Set to true/false in `app.vue`, by a watch on `route.query.productCode`. */
    canUsePreferencesMenu,
    mainTabs,
    productsActorOnlyCanDelegate,
  };
});
