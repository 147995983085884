/**
 * Enable arrow navigation on targeted child elements
 */
export default {
  mounted(el, { value }) {
    if (!value) {
      const ARROW_UP_KEY = 38;
      const ARROW_DOWN_KEY = 40;

      let currentIndex = 0;
      let navigableElements = el.querySelectorAll('[data-arrow-navigable-trigger], [data-arrow-navigable-item]');
      const navigableTrigger = navigableElements[0];

      const handleKeyDown = (e) => {
        if (navigableElements) {
          // Remove all event listeners from the current navigableElements
          for (let i = 0; i < navigableElements.length; i += 1) {
            navigableElements[i].removeEventListener('keydown', handleKeyDown);
            if (e.target === navigableElements[i]) {
              currentIndex = i;
            }
          }
        }

        // Update the navigableElements and add eventListeners to it
        navigableElements = el.querySelectorAll('[data-arrow-navigable-trigger], [data-arrow-navigable-item]');

        if (navigableElements.length >= 1) {
          for (let i = 0; i < navigableElements.length; i += 1) {
            navigableElements[i].addEventListener('keydown', handleKeyDown);
          }
        }
        switch (e.keyCode) {
          case ARROW_UP_KEY:
            e.preventDefault();
            currentIndex = (currentIndex === 0)
              ? 0
              : currentIndex - 1;
            navigableElements[currentIndex].focus();
            break;
          case ARROW_DOWN_KEY:
            e.preventDefault();
            currentIndex = ((currentIndex + 1) === navigableElements.length)
              ? currentIndex
              : currentIndex + 1;
            navigableElements[currentIndex].focus();
            break;
          default:
        }
      };

      const handleClick = () => {
        currentIndex = 0;
        navigableElements[currentIndex].focus();
      };

      if (navigableElements.length >= 1) {
        navigableTrigger.addEventListener('keydown', handleKeyDown);
        navigableTrigger.addEventListener('click', handleClick);
      }
    }
  },
};
