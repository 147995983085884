import * as types from '@/store/modules/system-management/mutation-types';

const initialState = () => ({
  product: undefined,
  products: [],
  preference: undefined,
  preferences: [],
  preferenceValue: undefined,
  futurePreferenceValue: undefined,
  preferenceValues: [],
  preferenceValueList: [],
  preferenceValueListInfo: { next: 0, hasMore: false, total: 0 },
  preferenceValueChanges: 0,
  roles: [],
  privileges: [],
  preferenceTypes: [],
});

const getters = {
  product: (state) => state.product,
  products: (state) => state.products,
  preference: (state) => state.preference,
  preferences: (state) => state.preferences,
  preferenceValue: (state) => state.preferenceValue,
  futurePreferenceValue: (state) => state.futurePreferenceValue,
  preferenceValues: (state) => state.preferenceValues,
  preferenceValueList: (state) => state.preferenceValueList,
  preferenceValueListInfo: (state) => state.preferenceValueListInfo,
  preferenceValueChanges: (state) => state.preferenceValueChanges,
  roles: (state) => state.roles,
  privileges: (state) => state.privileges,
  preferenceTypes: (state) => state.preferenceTypes,
};

const actions = {
  storeProduct({ commit }, product) {
    commit(types.STORE_PRODUCT, product);
  },
  storeProducts({ commit }, products) {
    commit(types.STORE_PRODUCTS, products);
  },
  storePreference({ commit }, preference) {
    commit(types.STORE_PREFERENCE, preference);
  },
  storePreferenceValue({ commit }, preferenceValue) {
    commit(types.STORE_PREFERENCE_VALUE, preferenceValue);
  },
  storeFuturePreferenceValue({ commit }, futurePreferenceValue) {
    commit(types.STORE_FUTURE_PREFERENCE_VALUE, futurePreferenceValue);
  },
  storePreferenceValues({ commit }, preferenceValues) {
    commit(types.STORE_PREFERENCE_VALUES, preferenceValues);
  },
  storePreferences({ commit }, preferences) {
    commit(types.STORE_PREFERENCES, preferences);
  },
  storePreferenceValueList({ commit }, preferenceValueList) {
    commit(types.STORE_PREFERENCE_VALUE_LIST, preferenceValueList);
  },
  storePreferenceValueChanges({ commit }, preferenceValueChanges) {
    commit(types.STORE_PREFERENCE_VALUE_CHANGES, preferenceValueChanges);
  },
  addToPreferenceValueList({ commit }, preferenceValueList) {
    commit(types.ADD_TO_PREFERENCE_VALUE_LIST, preferenceValueList);
  },
  storePreferenceValueListInfo({ commit }, preferenceValueListInfo) {
    commit(types.STORE_PREFERENCE_VALUE_LIST_INFO, preferenceValueListInfo);
  },
  reset({ commit }) {
    commit(types.RESET);
  },
  resetProduct({ commit }) {
    commit(types.RESET_PRODUCT);
  },
  resetProducts({ commit }) {
    commit(types.RESET_PRODUCTS);
  },
  resetPreference({ commit }) {
    commit(types.RESET_PREFERENCE);
  },
  resetPreferenceValue({ commit }) {
    commit(types.RESET_PREFERENCE_VALUE);
  },
  resetPreferenceValues({ commit }) {
    commit(types.RESET_PREFERENCE_VALUES);
  },
  resetPreferences({ commit }) {
    commit(types.RESET_PREFERENCES);
  },
  storeRoles({ commit }, roles) {
    commit(types.STORE_ROLES, roles);
  },
  resetRoles({ commit }) {
    commit(types.RESET_ROLES);
  },
  storePrivileges({ commit }, privileges) {
    commit(types.STORE_PRIVILEGES, privileges);
  },
  resetPrivileges({ commit }) {
    commit(types.RESET_PRIVILEGES);
  },
  storePreferenceTypes({ commit }, preferenceTypes) {
    commit(types.STORE_PREFERENCETYPES, preferenceTypes);
  },
  resetPreferenceTypes({ commit }) {
    commit(types.RESET_PREFERENCETYPES);
  },
};

const mutations = {
  [types.STORE_PRODUCT](state, product) {
    state.product = product;
  },
  [types.STORE_PRODUCTS](state, products) {
    state.products = products;
  },
  [types.STORE_PREFERENCE](state, preference) {
    state.preference = preference;
  },
  [types.STORE_PREFERENCES](state, preferences) {
    state.preferences = preferences;
  },
  [types.STORE_PREFERENCE_VALUE](state, preferenceValue) {
    state.preferenceValue = preferenceValue;
  },
  [types.STORE_FUTURE_PREFERENCE_VALUE](state, futurePreferenceValue) {
    state.futurePreferenceValue = futurePreferenceValue;
  },
  [types.STORE_PREFERENCE_VALUES](state, preferenceValues) {
    state.preferenceValues = preferenceValues;
  },
  [types.STORE_PREFERENCE_VALUE_LIST](state, preferenceValueList) {
    state.preferenceValueList = preferenceValueList;
  },
  [types.STORE_PREFERENCE_VALUE_CHANGES](state, preferenceValueChanges) {
    state.preferenceValueChanges = preferenceValueChanges;
  },
  [types.ADD_TO_PREFERENCE_VALUE_LIST](state, preferenceValueList) {
    state.preferenceValueList = [...state.preferenceValueList, ...preferenceValueList];
  },
  [types.STORE_PREFERENCE_VALUE_LIST_INFO](state, preferenceValueListInfo) {
    state.preferenceValueListInfo = preferenceValueListInfo;
  },
  [types.RESET](state) {
    Object.assign(state, initialState());
  },
  [types.RESET_PRODUCT](state) {
    const { product } = initialState();
    state.product = product;
  },
  [types.RESET_PRODUCTS](state) {
    const { products } = initialState();
    state.products = products;
  },
  [types.RESET_PREFERENCE](state) {
    const { preference } = initialState();
    state.preference = preference;
  },
  [types.RESET_PREFERENCES](state) {
    const { preferences } = initialState();
    state.preferences = preferences;
  },
  [types.RESET_PREFERENCE_VALUE](state) {
    const { preferenceValue } = initialState();
    state.preferenceValue = preferenceValue;
  },
  [types.RESET_PREFERENCE_VALUES](state) {
    const { preferenceValues } = initialState();
    state.preferenceValues = preferenceValues;
  },
  [types.STORE_ROLES](state, roles) {
    state.roles = roles;
  },
  [types.RESET_ROLES](state) {
    const { roles } = initialState();
    state.roles = roles;
  },
  [types.STORE_PRIVILEGES](state, privileges) {
    state.privileges = privileges;
  },
  [types.RESET_PRIVILEGES](state) {
    const { privileges } = initialState();
    state.privileges = privileges;
  },
  [types.STORE_PREFERENCETYPES](state, preferenceTypes) {
    state.preferenceTypes = preferenceTypes;
  },
  [types.RESET_PREFERENCETYPES](state) {
    const { preferenceTypes } = initialState();
    state.preferenceTypes = preferenceTypes;
  },
};

const module = {
  namespaced: true,
  state: { ...initialState() },
  getters,
  actions,
  mutations,
};

export default module;
