<template>
  <div class="toast__wrapper">
    <div
      :class="showToast ? 'toast toast--show' : 'toast'"
      aria-hidden="false"
      role="status"
      @mouseover="onHover"
      @mouseleave="onLeaveHover"
    >
      <div class="toast__body-wrapper">
        <div class="toast__body">
          <p class="toast__text">
            {{ toastContent.message }}
          </p>
        </div>
        <div
          v-if="toastContent.actionCallback"
          class="toast__action"
        >
          <button
            type="button"
            class="btn btn--small btn--plain toast__action-message"
            aria-label="Action"
          >
            <span
              class="btn__inner"
              @click="toastContent.actionCallback"
            >
              <span class="btn__label">
                {{ toastContent.actionLabel }}
              </span>
            </span>
          </button>
        </div>
      </div>
      <div>
        <div class="toast__divider">
          <div class="toast__divider-visible" />
          <div class="toast__divider-cover-container">
            <div class="toast__divider-cover" />
          </div>
        </div>
        <div class="toast__close-btn">
          <button
            type="button"
            class="btn btn--xsmall btn--icon-tertiary-inverse"
            @click="closeToast"
          >
            <span class="btn__inner">
              <svg
                focusable="false"
                viewBox="0 0 24 24"
                class="svg-icon btn__icon"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="m12.0006 13.4148 2.8283 2.8283 1.4142-1.4142-2.8283-2.8283 2.8283-2.8283-1.4142-1.4142-2.8283
              2.8283L9.172 7.7578 7.7578 9.172l2.8286 2.8286-2.8286 2.8285 1.4142 1.4143 2.8286-2.8286z"
                />
              </svg>
              <span class="btn__label">Dismiss notification</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ref, computed } from 'vue';

export default {
  setup() {
    const showToast = ref(false);
    const timeOutID = ref();

    const toastContent = ref({
      message: '',
      actionLabel: '',
      actionCallback: () => { },
    });

    const calcDuration = computed(() => {
      const timeShown = toastContent.value.message.length * 50;
      // eslint-disable-next-line no-nested-ternary
      return (timeShown > 10000) ? 10000 : (timeShown < 5000 ? 5000 : timeShown);
    });

    const displayToast = () => {
      showToast.value = true;
    };

    const closeToast = () => {
      clearTimeout(timeOutID.value);
      showToast.value = false;
    };

    // Import useUtils from store/utils. Deconstruct toastRef from useUtilsStore.
    // Toast called via toastRef.value.toastAnimation({ obj }).
    const toastAnimation = (toastParams) => {
      if (!toastParams.message) { return; }

      clearTimeout(timeOutID.value);
      closeToast();

      setTimeout(() => {
        toastContent.value.message = toastParams.message;
        toastContent.value.actionLabel = toastParams.actionLabel;
        toastContent.value.actionCallback = toastParams.actionCallback;

        displayToast();

        timeOutID.value = setTimeout(() => {
          closeToast();
        }, calcDuration.value);
      }, 300);
    };

    const onHover = () => {
      clearTimeout(timeOutID.value);
    };

    const onLeaveHover = () => {
      timeOutID.value = setTimeout(() => {
        closeToast();
      }, 2500);
    };

    return {
      showToast,
      toastContent,
      toastAnimation,
      closeToast,
      onHover,
      onLeaveHover,
    };
  },
};
</script>

<style scoped>
  .toast__wrapper {
    padding: 0 !important;
  }

  @media (min-width: 37.5rem) {
    .toast {
      top: 10rem;
    }
  }

  @media (min-width: 64rem) {
    .toast {
      top: 8.5rem;
    }
  }
</style>
