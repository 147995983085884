<template>
  <div>
    <link
      rel="stylesheet"
      href="https://www.ikea.com/global/assets/fonts/en/fonts.css"
    >
    <Toast
      ref="toastRef"
    />
    <progress-bar
      v-if="servicesFetched"
      :balance="loadingBalance"
      :progress="loadingProgress"
      :error="loadingError"
    />
    <suspense v-if="servicesFetched">
      <router-view />
    </suspense>
  </div>
</template>

<script>
import {
  watch, ref, onMounted, onBeforeMount,
} from 'vue';
import axios from 'axios';
import { usePreferences } from '@/composables/system-management/preferences';

import Toast from '@/components/shared/toast.vue';
import ProgressBar from '@/components/shared/progress-bar.vue';

import { useUtilsStore } from '@/store/utils';
import { useActorStore } from '@/store/actor';
import { useRouter, useRoute } from 'vue-router';
import { useUserEvaluationStore } from '@/store/user-evaluation';

import { requestForAuthService } from '@/services/api-utils';

import { storeToRefs } from 'pinia';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  components: {
    Toast,
    ProgressBar,
  },

  setup() {
    const toastRef = ref();

    const auth0 = useAuth0();

    const {
      storeToastRef,
      pushLoading,
      popLoading,
      addError,
    } = useUtilsStore();

    const {
      fetchHasProductViewPrivilege,
    } = useUserEvaluationStore();

    const {
      hasProductViewPrivilege,
    } = storeToRefs(useUserEvaluationStore());

    const {
      hasPreferences,
    } = usePreferences();

    const {
      loadingBalance,
      loadingProgress,
      loadingError,
    } = storeToRefs(useUtilsStore());

    const {
      authServiceProducts,
      canUsePreferencesMenu,
    } = storeToRefs(useActorStore());

    const router = useRouter();
    const route = useRoute();

    const servicesFetched = ref(false);

    const getServices = async () => {
      let actorsServices = [];
      const accessToken = await auth0.getAccessTokenSilently();
      try {
        actorsServices = await requestForAuthService(
          process.env.VUE_APP_API_AUTH_SERVICE, 'get', '/services', undefined, accessToken,
        );
      } catch (err) {
        console.error('Error when getting services.', err);
      }
      let allServicesWithInfo = [];
      try {
        allServicesWithInfo = await requestForAuthService(
          `${process.env.VUE_APP_API_ILOFF}/bff/iloff/apps`, 'get', '', undefined, accessToken,
        );
      } catch (err) {
        console.error('Error when getting all ILOFF services.', err);
      }

      // INTEGRATION_PLATFORM should not be included!
      const servicesNoIntegrationPlatform = actorsServices.results?.filter((s) => s !== 'INTEGRATION_PLATFORM');

      const actorServicesWithInfo = servicesNoIntegrationPlatform?.map((service) => {
        const serviceWithAccess = allServicesWithInfo.find((s) => s.systemMasterAbbreviation === service);
        return {
          name: serviceWithAccess?.label || service,
          acronym: serviceWithAccess?.acronym,
          code: service,
          url: process.env.VUE_APP_API_ILOFF + '/atkomst/access?app=' + serviceWithAccess?.id, // eslint-disable-line prefer-template
        };
      });
      authServiceProducts.value = actorServicesWithInfo;
    };

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    onBeforeMount(async () => {
      await router.isReady();
      // eslint-disable-next-line no-await-in-loop
      while (auth0.isLoading.value) { await delay(500); }
      if (!auth0.isAuthenticated.value) { auth0.loginWithRedirect(); }
      await getServices();
      servicesFetched.value = true;
    });

    onMounted(() => {
      storeToastRef(toastRef);

      axios.interceptors.request.use(async (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${await auth0.getAccessTokenSilently()}`;
        return config;
      });

      axios.interceptors.request.use((config) => {
        pushLoading();
        return config;
      }, (error) => {
        addError({
          status: error.request?.status,
          message: error.message,
          url: `${error.config.baseURL}${error.config.url}`,
        });
        popLoading(true); // true = indicating error
        return Promise.reject(error);
      });

      axios.interceptors.response.use((response) => {
        popLoading();
        return response;
      }, (error) => {
        addError({
          status: error.response?.status || error.response?.data?.status,
          message: error.response?.data?.error?.message
            || error.response?.data?.message
            || (typeof error.response?.data?.error === 'string' ? error.response?.data?.error : null)
            || error.message,
          url: `${error.config.baseURL}${error.config.url}`,
        });
        popLoading(true); // true = indicating error
        return Promise.reject(error);
      });
    });

    watch(() => route.query.productCode, async () => {
      if (!route.query.productCode || route.query.productCode === 'All products') return;
      // eslint-disable-next-line no-await-in-loop
      while (auth0.isLoading.value) { await delay(100); }
      const state = auth0;
      const payload = {
        productCode: 'DFP',
        hierarchyNodeType: 'PRODUCT',
        privilegeCode: 'ViewProduct',
        userId: state.user.value['https://accounts.ikea.com/networkid'],
        hierarchyCode: 'Product',
        hierarchyNodeCode: route.query.productCode,
      };
      console.log(state);
      const success = await fetchHasProductViewPrivilege(payload);
      if (success && hasProductViewPrivilege.value.hasPrivilege) {
        canUsePreferencesMenu.value = await hasPreferences({ productCode: route.query.productCode });
      }
    }, { immediate: true });

    return {
      toastRef,
      loadingBalance,
      loadingProgress,
      loadingError,
      servicesFetched,
    };
  },
};
</script>

<style>
@import './assets/styles/main.css';
</style>
