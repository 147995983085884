/* eslint-disable import/prefer-default-export */
import {
  getHasPreferenceValue,
  getPreferenceValue,
  getHasPrivilege,
  getHierarchyNodesForPrivilege,
} from '@/services/user-evaluation';

import { getProducts } from '@/services/system-management';

import { useUtilsStore } from '@/store/utils';
import { useActorStore } from '@/store/actor';

import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';

import { union, uniqBy } from 'lodash';

const defaultState = {
  products: [],
  privileges: [],
  // TODO: privileges need to be split into preferences/users/roles etc
  hasViewPrivilege: false,
  hasWritePrivilege: false,
  hasProductViewPrivilege: false,
  hasProductWritePrivilege: false,
  hasPreferenceViewPrivilege: false,
  hasPreferenceWritePrivilege: false,
};

export const useUserEvaluationStore = defineStore('userEvaluation', () => {
  const { compareByProperty } = useUtilsStore();
  const { authServiceProducts, actor, productsActorOnlyCanDelegate } = storeToRefs(useActorStore());
  const { loadActor } = useActorStore();

  const products = ref(defaultState.products);
  const privileges = ref(defaultState.privileges);
  const hasViewPrivilege = ref(defaultState.hasViewPrivilege);
  const hasWritePrivilege = ref(defaultState.hasWritePrivilege);

  const hasProductViewPrivilege = ref(defaultState.hasProductViewPrivilege);
  const hasProductWritePrivilege = ref(defaultState.hasProductWritePrivilege);

  const hasPreferenceViewPrivilege = ref(defaultState.hasPreferenceViewPrivilege);
  const hasPreferenceWritePrivilege = ref(defaultState.hasPreferenceWritePrivilege);

  /**
   * Fetch and store the view rights for the given user and product.
   * @param {*} hierarchyNodeCode
   */
  const fetchHasViewPrivilege = async (query) => {
    try {
      const payload = await getHasPrivilege(query);
      hasViewPrivilege.value = payload;
      return true;
    } catch (err) {
      console.log(
        'Error in fetchHasViewPrivilege:',
        err.response?.status,
        err.response?.data.error?.message,
      );
      return false;
    }
  };

  /**
   * Fetch and store the write rights for the given user and the product
   * @param {*} hierarchyNodeCode
   */
  const fetchHasWritePrivilege = async (query) => {
    try {
      const payload = await getHasPrivilege(query);
      hasWritePrivilege.value = payload.hasPrivilege;
      return true;
    } catch (err) {
      console.log('Error in fetchHasWritePrivilege:', err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  /**
   * Fetch and store the view rights for the given user and product.
   * @param {*} hierarchyNodeCode
   */
  const fetchHasProductViewPrivilege = async (query) => {
    try {
      const payload = await getHasPrivilege(query);
      hasProductViewPrivilege.value = payload;
      return true;
    } catch (err) {
      console.log('Error in fetchHasProductViewPrivilege:', err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  /**
   * Fetch and store the write rights for the given user and the product
   * @param {*} hierarchyNodeCode
   */
  const fetchHasProductWritePrivilege = async (query) => {
    try {
      const payload = await getHasPrivilege(query);
      hasProductWritePrivilege.value = payload.hasPrivilege;
      return true;
    } catch (err) {
      console.log('Error in fetchHasProductWritePrivilege:', err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  /**
   * Fetch and store the view rights for the given user and product.
   * @param {*} hierarchyNodeCode
   */
  const fetchHasPreferenceViewPrivilege = async (query) => {
    try {
      const payload = await getHasPrivilege(query);
      hasPreferenceViewPrivilege.value = payload;
      return true;
    } catch (err) {
      console.log('Error in fetchHasPreferenceViewPrivilege:', err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  /**
   * Fetch and store the write rights for the given user and the product
   * @param {*} hierarchyNodeCode
   */
  const fetchHasPreferenceWritePrivilege = async (query) => {
    try {
      const payload = await getHasPrivilege(query);
      hasPreferenceWritePrivilege.value = payload.hasPrivilege;
      return true;
    } catch (err) {
      console.log('Error in fetchHasPreferenceWritePrivilege:',
        err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  // Get sorted list of products including 'All products'
  const sortedProducts = computed(() => {
    let productList = [];
    if (products.value?.length) {
      productList = products.value;
      if (authServiceProducts.value?.length) {
        productList = productList.concat(authServiceProducts.value);
      }
    } else if (authServiceProducts.value?.length) {
      productList = authServiceProducts.value;
    }
    productList.sort(compareByProperty('name'));
    const index = productList.findIndex((p) => p.code === 'All products');
    if (index >= 0) {
      productList.splice(index, 1);
    }
    productList.splice(0, 0, { name: 'All products', code: 'All products' });
    productList.value = productList;
    return productList;
  });

  const queryProduct = (productCode) => sortedProducts.value.filter((p) => p.code === productCode).pop();

  const fetchProductsForActor = async (query) => {
    try {
      const payload = await getHierarchyNodesForPrivilege(query);
      // eslint-disable-next-line arrow-body-style
      const theProducts = payload.hierarchyNodes.map((node) => {
        return { name: node.hierarchyNodeName, code: node.hierarchyNodeCode };
      });
      if (!actor.value) {
        await loadActor();
      }

      // Products that the actor can delegate
      const { delegatableRoles } = actor.value;
      const { delegatablePrivileges } = actor.value;
      const productsActorCanDelegate = union(delegatableRoles, delegatablePrivileges);

      // Get list of all products
      const allProds = await getProducts();

      // Add the names of the products to the codes
      const productsWithNames = productsActorCanDelegate
        ?.map((p) => allProds.products?.find((prod) => prod.code === p.productCode));

      if (productsWithNames) {
        products.value = uniqBy(union(theProducts, productsWithNames), 'code');
        productsActorOnlyCanDelegate.value = uniqBy(productsWithNames, 'code');
      }
      return true;
    } catch (err) {
      console.log('Error in fetchProductsForActor:', err, err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  return {
    products,
    sortedProducts,
    fetchProductsForActor,
    queryProduct,
    privileges,
    hasViewPrivilege,
    hasWritePrivilege,
    hasProductViewPrivilege,
    hasProductWritePrivilege,
    hasPreferenceViewPrivilege,
    hasPreferenceWritePrivilege,
    fetchHasViewPrivilege,
    fetchHasWritePrivilege,
    fetchHasProductViewPrivilege,
    fetchHasProductWritePrivilege,
    fetchHasPreferenceViewPrivilege,
    fetchHasPreferenceWritePrivilege,
    getHierarchyNodesForPrivilege,
    getHasPreferenceValue,
    getPreferenceValue,
  };
});
