import { request } from '@/services/api-utils';

export const getProducts = async () => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'get', '/products');

export const getRoles = async ({ productCode }) => request(
  process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'get', '/roles?'
  + `productCode=${productCode}`,
);

/**
 * @param {Object} param0 - The object containing the query properties.
 * @returns
 */
export const createRole = async ({
  productCode,
  name,
  userNotification,
  privilegeCodes,
},
) => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'post', '/role', {
  productCode,
  name,
  userNotification,
  privilegeCodes,
});

/**
 * @param {Object} param0 - The object containing the query properties.
 * @returns
 */
export const createPrivilege = async ({
  productCode,
  privilegeCode,
  name,
  description,
  hierarchyCode,
  userNotify,
},
) => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'post', '/privilege', {
  productCode,
  privilegeCode,
  name,
  description,
  hierarchyCode,
  userNotify,
});

/**
 * @param {Object} param0 - The object containing the query properties.
 * @returns
 */
export const updatePrivilege = async ({
  productCode,
  privilegeCode,
  name,
  description,
  hierarchyCode,
  userNotify,
},
) => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'put', '/privilege', {
  productCode,
  privilegeCode,
  name,
  description,
  hierarchyCode,
  userNotify,
});

/**
 * @param {Object} param0 - The object containing the query properties.
 * @returns
 */
export const updateRole = async ({
  productCode,
  name,
  userNotification,
  privilegeCodes,
},
) => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'put', '/role', {
  productCode,
  name,
  userNotification,
  privilegeCodes,
});

export const deleteRole = async ({
  productCode,
  roleName,
},
) => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'delete',
  `/role?productCode=${productCode}`
+ `&roleName=${roleName}`);

export const getPrivileges = async ({ productCode }) => request(
  process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'get', '/privileges?'
  + `productCode=${productCode}`,
);

export const getPreferences = async ({ productCode }) => request(
  process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'get', '/preferences?'
  + `productCode=${productCode}`,
);

export const getPreferenceTypes = async ({ productCode }) => request(
  process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'get', '/preferencetypes?'
  + `productCode=${productCode}`,
);

/**
 * Gets the preference value from the API endpoint mathing the query properties.
 * @param {Object} param0 - The object containing the query properties.
 * @param {string} productCode - The product code.
 * @param {string} preferenceCode - The preference code.
 * @param {string} hierarchyCode - The hierarchy code.
 * @param {string} hierarchyNodeCode - The hierarchy node code.
 * @param {string} hierarchyNodeType - The hierarchy node type.
 * @param {string} startTime - The start time.
 * @returns
 */
export const getPreferenceValue = async ({
  productCode,
  preferenceCode,
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
  startTime,
},
) => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'get', '/preferencevalue?'
+ `productCode=${productCode}`
+ `&preferenceCode=${preferenceCode}`
+ `&hierarchyCode=${hierarchyCode}`
+ `&hierarchyNodeCode=${hierarchyNodeCode}`
+ `&hierarchyNodeType=${hierarchyNodeType}`
+ `&startTime=${startTime}`);

/**
 * Gets the preference values from the API endpoint mathing the query properties.
 * @param {Object} param0 - The object containing the query properties.
 * @param {string} productCode - The product code.
 * @param {string} preferenceCode - The preference code.
 * @param {string} hierarchyCode - The hierarchy code.
 * @param {string} hierarchyNodeCode - The hierarchy node code.
 * @param {string} hierarchyNodeType - The hierarchy node type.
 * @returns
 */
export const getPreferenceValues = async ({
  productCode,
  preferenceCode,
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
  ancestors = false,
},
) => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'get', '/preferencevalues?'
+ `productCode=${productCode}`
+ `&preferenceCode=${preferenceCode}`
+ `&hierarchyCode=${hierarchyCode}`
+ `&hierarchyNodeCode=${hierarchyNodeCode}`
+ `&hierarchyNodeType=${hierarchyNodeType}`
+ `&ancestors=${ancestors}`);

/**
 * Gets the preference value list from the API endpoint matching the query properties.
 * @param {Object} param0 - The object containing the query properties.
 * @param {string} productCode - The product code.
 * @param {string} offset - The offset in the persisted endpoint list to start off from.
 * @param {string} limit - The limit in the number of rows from the endpoint.
 * @param {string} hierarchyCode - The hierarchy code.
 * @param {string} hierarchyNodeCode - The hierarchy node code.
 * @param {string} hierarchyNodeType - The hierarchy node type.
 * @returns
 */
export const getPreferenceValueList = async ({
  productCode,
  offset,
  limit,
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
  preferenceName,
},
) => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'get', '/preferencevaluelist?'
+ `productCode=${productCode}`
+ `&offset=${offset}`
+ `&limit=${limit}`
+ `&hierarchyCode=${hierarchyCode || ''}`
+ `&hierarchyNodeCode=${hierarchyNodeCode || ''}`
+ `&hierarchyNodeType=${hierarchyNodeType || ''}`
+ `&preferenceName=${preferenceName || ''}`);

/**
 * @param {Object} param0 - The object containing the query properties.
 * @returns
 */
export const putPreferenceValue = async ({
  productCode,
  preferenceCode,
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
  startTime,
  values,
},
) => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'put', '/preferencevalue', {
  productCode,
  preferenceCode,
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
  startTime,
  values,
});

/**
 * @param {Object} param0 - The object containing the query properties.
 * @returns
 */
export const postPreferenceValue = async ({
  productCode,
  preferenceCode,
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
  startTime,
  values,
},
) => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'post', '/preferencevalue', {
  productCode,
  preferenceCode,
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
  startTime,
  values,
});

/**
 * @param {Object} param0 - The object containing the query properties.
 * @returns
 */
export const deletePreferenceValue = async ({
  productCode,
  preferenceCode,
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
  startTime,
},
) => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'delete',
  `/preferencevalue?productCode=${productCode}`
+ `&preferenceCode=${preferenceCode}`
+ `&hierarchyCode=${hierarchyCode}`
+ `&hierarchyNodeCode=${hierarchyNodeCode}`
+ `&hierarchyNodeType=${hierarchyNodeType}`
+ `&startTime=${startTime}`);

export const deletePrivilege = async ({
  productCode,
  privilegeCode,
},
) => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'delete',
  `/privilege?productCode=${productCode}`
+ `&privilegeCode=${privilegeCode}`);
