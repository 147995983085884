<template>
  <div
    ref="barRef"
    :class="{
      'progress-bar': true,
      'progress-bar--animate': isIncreasing,
      'progress-bar--error': error,
    }"
  />
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    balance: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0.0,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const barRef = ref();
    const isIncreasing = ref(false);

    watch(() => props.progress, (newProgress, oldProgress) => {
      if (barRef.value) {
        isIncreasing.value = newProgress > oldProgress;
        const percentage = parseInt(newProgress * 100, 10);
        barRef.value.style = `width: ${percentage}%`;
      }
    }, { immediate: true });

    return {
      barRef,
      isIncreasing,
    };
  },
};
</script>

<style scoped>
.progress-bar {
  position: sticky;
  top: 0;
  z-index: 2;
  width: 0;
  height: 0.25rem;
  margin-bottom: -0.25rem;
  background: #ffcd3f;
}

.progress-bar--animate {
  transition: width 0.25s ease-in-out;
}

.progress-bar--error {
  background: #e00751;
}
</style>
