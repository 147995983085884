import axios from 'axios';

export const extractQueryParams = (queries) => Object.entries(queries)
  .reduce((acc, query, idx) => (idx === 0
    ? `${query[0]}=${query[1]}`
    : `${acc}&${query[0]}=${query[1]}`), '');

export const request = async (baseURL, method, resource, data) => axios.request({
  baseURL: baseURL.replace(/\/$/, ''),
  json: true,
  method,
  url: resource,
  data,
  headers: {
  },
}).then((req) => req.data);

export const requestForAuthService = async (baseURL, method, resource, data, token) => {
  const headers = {
    'X-Backend-Service-Authorization': `Bearer ${token}`,
    Accept: '*/*',
  };
  return axios.request({
    baseURL: baseURL.replace(/\/$/, ''),
    json: true,
    method,
    url: resource,
    data,
    headers,
  }).then((req) => req.data);
};
