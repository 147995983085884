/* eslint-disable import/prefer-default-export */
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import * as hash from 'object-hash';
import moment from 'moment';

import { ref } from 'vue';

const defaultState = {
  toastRef: undefined,
  offset: 0,
  limit: 20,
  loadingBalance: 0,
  loadingProgress: 0.0,
  loadingError: false,
  errors: [],
  preferenceRoute: '',
};

export const useUtilsStore = defineStore('utils', () => {
  const initialState = () => cloneDeep(defaultState);

  const toastRef = ref(defaultState.toastRef);
  const offset = ref(defaultState.offset);
  const limit = ref(defaultState.limit);
  const loadingBalance = ref(defaultState.loadingBalance);
  const loadingProgress = ref(defaultState.loadingProgress);
  const loadingError = ref(defaultState.loadingError);
  const errors = ref(defaultState.errors);
  const preferenceRoute = ref(defaultState.preferenceRoute);

  const progressStep = 0.2;
  let progressTimeoutId;

  /**
  * @deprecated Use the state param directly instead of the store function.
  */
  const storeToastRef = (newToastRef) => {
    toastRef.value = newToastRef;
  };

  /**
  * @deprecated Use the state param directly instead of the store function.
  */
  const storeOffset = (newOffset) => {
    offset.value = newOffset;
  };

  const resetOffset = () => {
    offset.value = initialState().offset;
  };

  /**
  * @deprecated Use the state param directly instead of the store function.
  */
  const storeLimit = (newOffset) => {
    limit.value = newOffset;
  };

  const resetLimit = () => {
    limit.value = initialState().limit;
  };

  const pushLoading = () => {
    clearTimeout(progressTimeoutId);
    loadingBalance.value += 1;
    loadingProgress.value += (1.0 - loadingProgress.value) * progressStep;
  };

  const popLoading = (isError) => {
    clearTimeout(progressTimeoutId);
    loadingBalance.value = Math.max(0, loadingBalance.value - 1);
    loadingError.value = isError || loadingError.value;

    if (!loadingBalance.value) { // finish progress
      progressTimeoutId = setTimeout(() => {
        loadingProgress.value = 1.0;
        progressTimeoutId = setTimeout(() => {
          loadingProgress.value = 0.0;
          loadingError.value = false;
        }, 250);
      }, 250);
    } else {
      loadingProgress.value += (1.0 - loadingProgress.value) * progressStep;
    }
  };

  const addError = (error) => {
    errors.value.push(error);
  };

  const resetErrors = () => {
    errors.value = initialState().errors;
  };

  /**
  * @deprecated Use the state param directly instead of the store function.
  */
  const storePreferenceRoute = (theRoute) => {
    preferenceRoute.value = theRoute;
  };

  const toHash = (object) => hash(object);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const formatDate = (date) => {
    if (date) {
      return moment(String(date)).format('D MMM YYYY, hh:mm');
    }
    return '';
  };

  const formatDateISO = (date) => {
    if (date) {
      return moment(String(date)).format('YYYY-MM-D, hh:mm');
    }
    return '';
  };

  const areObjectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    let equal = true;
    keys1.forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        equal = false;
      }
    });
    return equal;
  };

  const compareByProperty = (prop = 'name', order = 'ASC') => {
    const sortOrder = (order === 'DESC' ? -1 : 1);

    return (a, b) => {
      if (a[prop] < b[prop]) {
        return -1 * sortOrder;
      }
      if (a[prop] > b[prop]) {
        return 1 * sortOrder;
      }
      return 0;
    };
  };
  const compareByPropertyIgnoreCase = (prop = 'name', order = 'ASC') => {
    const sortOrder = (order === 'DESC' ? -1 : 1);

    return (a, b) => {
      const f = a[prop].toLowerCase();
      const s = b[prop].toLowerCase();
      if (f < s) {
        return -1 * sortOrder;
      }
      if (f > s) {
        return 1 * sortOrder;
      }
      return 0;
    };
  };

  /**
   * Calculates and constructs a human readable list of changes.
   * @param {*} oldValue The old value
   * @param {*} newValue The new value
   * @param {*} title The header of the result (e.g. preference code)
   * @param {('single', 'collection', 'dictionary')} type single, dictionary or collection
   * @returns An array with title as first element, thereafter one element for each change.
   */
  const calcChanges = (oldValue, newValue, title, type) => {
    if (type === 'single') return [`${title} ${oldValue} ==> ${newValue}`];
    /* For single values
    Preference Code oldValue -> newValue
    */

    if (type === 'dictionary') return ['There is nothing to see here'];
    /* For dictionaries
    Preference Code
    oldValue.key: oldValue.value -> newValue.value
    newValue.key: newValue.value -> ADDED
    oldValue.key: oldValue.value -> DELETED
    */

    /* if (type === 'collection') {
      const addedValues = difference(newValue, oldValue);
      const deletedValues = difference(oldValue, newValue);
    } */
    /* For collections
    Preference Code
    oldValue -> newValue
    newValue -> ADDED
    oldValue -> DELETED
    */
    return ['There is nothing to see here'];
  };

  const unProxy = (proxy) => JSON.parse(JSON.stringify(proxy));

  return {
    offset,
    storeOffset,
    resetOffset,
    limit,
    storeLimit,
    resetLimit,
    toastRef,
    storeToastRef,
    errors,
    addError,
    resetErrors,
    loadingError,
    loadingBalance,
    loadingProgress,
    pushLoading,
    popLoading,
    preferenceRoute,
    storePreferenceRoute,
    toHash,
    formatDate,
    formatDateISO,
    calcChanges,
    unProxy,
    sleep,
    areObjectsEqual,
    compareByProperty,
    compareByPropertyIgnoreCase,
  };
});
