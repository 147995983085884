import { createApp } from 'vue';
import { createPinia } from 'pinia';

import { createAuth0 } from '@auth0/auth0-vue';
import App from '@/app.vue';
import store from '@/store';
import router from '@/router';
import arrowNavigable from '@/directives/arrow-navigable';

import '@ingka/core/dist/style.min.css';
import '@ingka/variables/design-tokens.css';

/**
 * We use an async initApp function to allow for configuration/setup that may also be async.
 */
const initApp = async () => {
  const app = createApp(App);
  app.use(createPinia());
  app.use(router);
  app.use(createAuth0({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    scope: process.env.VUE_APP_AUTH0_SCOPE,
    redirect_uri: window.location.origin,
  }));
  app.use(store);
  app.directive('arrow-navigable', arrowNavigable);

  const isProductionMode = process.env.NODE_ENV === 'production';
  if (isProductionMode) {
    console.log = () => {};
  }

  app.mount('#app');
};

initApp();
