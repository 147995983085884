import { request } from '@/services/api-utils';

// eslint-disable-next-line import/prefer-default-export
export const getPrivileges = async ({
  guId,
  userId,
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
  productCode,
},
) => request(process.env.VUE_APP_API_USER_EVALUATION, 'get', '/privileges?'
+ `userId=${userId}`
+ `&guId=${guId}`
+ `&hierarchyNodeType=${hierarchyNodeType}`
+ `&hierarchyNodeCode=${hierarchyNodeCode}`
+ `&hierarchyCode=${hierarchyCode}`
+ `&productCode=${productCode}`);

export const getHasPrivilege = async ({
  userId,
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
  productCode,
  privilegeCode,
},
) => request(process.env.VUE_APP_API_USER_EVALUATION, 'get', '/hasprivilege?'
+ `userId=${userId}`
+ `&hierarchyNodeType=${hierarchyNodeType}`
+ `&hierarchyNodeCode=${hierarchyNodeCode}`
+ `&hierarchyCode=${hierarchyCode}`
+ `&productCode=${productCode}`
+ `&privilegeCode=${privilegeCode}`);

export const getHierarchyNodesForPrivilege = async ({
  userId,
  hierarchyNodeType,
  productCode,
  privilegeCode,
},
) => request(process.env.VUE_APP_API_USER_EVALUATION, 'get', '/hierarchynodesforprivilege?'
+ `userId=${userId}`
+ `&hierarchyNodeType=${hierarchyNodeType}`
+ `&productCode=${productCode}`
+ `&privilegeCode=${privilegeCode}`);

export const getHasPreferenceValue = async ({
  userId,
  productCode,
  preferenceCode,
},
) => request(process.env.VUE_APP_API_USER_EVALUATION, 'get', '/haspreferencevalue?'
+ `userId=${userId}`
+ `&productCode=${productCode}`
+ `&preferenceCode=${preferenceCode}`);

export const getPreferenceValue = async ({
  userId,
  productCode,
  preferenceCode,
},
) => request(process.env.VUE_APP_API_USER_EVALUATION, 'get', '/preferencevalue?'
+ `userId=${userId}`
+ `&productCode=${productCode}`
+ `&preferenceCode=${preferenceCode}`);
