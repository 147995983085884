/* eslint-disable import/prefer-default-export */
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useUtilsStore } from '@/store/utils';

import {
  getPrivileges,
  getRoles,
} from '@/services/system-management';

import {
  getUser,
  getUsers,
  putUser,
  getPreferences,
  getUsersWithRole,
} from '@/services/user-management';

const defaultState = {
  user: {},
  users: [],
  searchQueries: {},
  preferences: [],
  privileges: [],
  roles: [],
};

export const useUserManagementStore = defineStore('userManagement', () => {
  const { areObjectsEqual } = useUtilsStore();

  const initialState = () => cloneDeep(defaultState);

  const preferences = ref(initialState().preferences);
  const user = ref(initialState().user);
  const users = ref(initialState().users);
  const searchQueries = ref(initialState().searchQueries);
  const privileges = ref(initialState().privileges);
  const roles = ref(initialState().roles);

  /**
  * @deprecated Use the state param directly instead of the store function.
  */
  const storePreferences = (prefs) => {
    preferences.value = prefs;
  };
  const storeRoles = (rs) => {
    roles.value = rs;
  };
  const resetPreferences = () => {
    preferences.value = initialState().preferences;
  };
  const resetRoles = () => {
    roles.value = initialState().roles;
  };
  const resetPrivileges = () => {
    privileges.value = initialState().privileges;
  };

  const fetchRoles = async ({ productCode }) => {
    try {
      const payload = await getRoles({ productCode });
      roles.value = payload.roles;
      return true;
    } catch (err) {
      console.error('Error in fetchRoles:', err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  /**
   * Return the index of the given role.
   * @param {Object} role The role to get index for.
   */
  const findRoleIndex = (role) => roles.value.findIndex((el) => el.name === role.name);

  /**
   * Fetch the users of the given role and product and put information
   * on if the role has users in the list of roles.
   * @param {Object} role The role to get users for.
   * @param {Object} productCode The product to get users for.
   */
  const fetchRoleUsers = async (role, productCode) => {
    const index = findRoleIndex(role);
    try {
      const roleUsers = await getUsersWithRole(
        { productCode, roleName: roles.value[index].name },
      );
      roles.value[index].hasUsers = roleUsers.globalUserIds.length > 0;
    } catch (err) {
      console.error('Error in fetchRoleUsers:', err.response?.status, err.response?.data.error?.message);
    }
  };

  /**
   * Adds role without breaking reactivity.
   * @param {Object} role The role to add
   */
  const addRole = (role) => {
    roles.value.push(role);
  };

  /**
   * Adds privilege without breaking reactivity.
   * @param {Object} role The role to add
   */
  const addPrivilege = (p) => {
    privileges.value.push(p);
  };

  /**
   * Removes privilege from role without breaking reactivity.
   * @param {Object} role The role to remove privilege from
   * @param {string} privilegeCode The privilege code to remove from the role
   */
  const deleteRolePrivilege = (role, privilegeCode) => {
    const roleIndex = roles.value.findIndex((el) => el.name === role.name);
    const privilegeIndex = roles.value[roleIndex].privilegeCodes.findIndex((el) => el === privilegeCode);
    roles.value[roleIndex].privilegeCodes.splice(privilegeIndex, 1);
  };

  /**
   * Removes privilege without breaking reactivity.
   * @param {string} privilegeCode The privilege code to remove
   */
  const removePrivilege = (privilegeCode) => {
    const index = privileges.value.findIndex((el) => el.privilegeCode === privilegeCode);
    if (index >= 0) {
      privileges.value.splice(index, 1);
    } else {
      console.error('Error when removing privilege: not found.');
    }
  };

  const fetchPreferences = async ({ productCodes }) => {
    try {
      const payload = await getPreferences({ productCodes });
      preferences.value = payload.preferences;
      return true;
    } catch (err) {
      console.error('Error in user fetchPreferences:', err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  const fetchUsers = async (queries) => {
    users.value = [];
    searchQueries.value = queries;
    const payload = await getUsers(queries); // waiting...
    if (areObjectsEqual(queries, searchQueries.value)) {
      // only store a result that still match the latest queries
      // to avoid problems with slow asynchronous searches
      users.value = payload.users;
    }
  };

  const fetchUser = async (queries) => {
    try {
      user.value = undefined;
      const payload = await getUser(queries);
      user.value = payload.user;
      return true;
    } catch (err) {
      console.error('Error in fetchUser:', err.response.status, err.response.data.error?.message);
      return false;
    }
  };

  const updateUser = async (queries) => {
    const status = {
      success: false,
      message: null,
    };
    try {
      const data = await putUser(queries);
      if (data.event?.status === 'SUCCESS') {
        status.success = true;
      }
      status.message = data.event?.caption;
    } catch (err) {
      console.error('Error in updateUser:', err.response.status, err.response.data.error?.message);
      status.message = err.response.data.error?.message
        || err.response.data.error?.code
        || err.response.status;
    }
    return status;
  };

  const fetchPrivileges = async ({ productCode }) => {
    try {
      const payload = await getPrivileges({ productCode });
      privileges.value = payload.privileges;
      return true;
    } catch (err) {
      console.error('Error in fetchPrivileges:', err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  return {
    preferences,
    fetchPreferences,
    storePreferences,
    resetPreferences,
    users,
    fetchUsers,
    user,
    fetchUser,
    getUser,
    updateUser,
    privileges,
    addPrivilege,
    getPrivileges,
    fetchPrivileges,
    resetPrivileges,
    searchQueries,
    roles,
    fetchRoleUsers,
    addRole,
    getRoles,
    fetchRoles,
    storeRoles,
    resetRoles,
    deleteRolePrivilege,
    removePrivilege,
  };
});
