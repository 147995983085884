import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'root',
    redirect: '/home',
  },
  {
    path: '/preferences',
    name: 'preferences',
    component: () => import(/* webpackChunkName: "preferences" */ '@/views/preferences-view.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home-view.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/users-view.vue'),
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '@/views/product-view.vue'),
  },
  {
    path: '/hierarchies',
    name: 'hierarchies',
    component: () => import(/* webpackChunkName: "hierarchies" */ '@/views/hierarchies-view.vue'),
  },
  {
    path: '/management',
    name: 'management',
    component: () => import(/* webpackChunkName: "management" */ '@/views/management-view.vue'),
  },
  // 404 catch all
  {
    path: '/:catchAll(.*)*',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/not-found-view.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'navigation__link--active',
  linkExactActiveClass: 'navigation__link--exact-active',
});

export default router;
