import { request } from '@/services/api-utils';

export const getPreferences = async ({ productCodes }) => request(
  process.env.VUE_APP_API_USER_MANAGEMENT, 'get', '/preferences?'
  + `productCodes=${productCodes}`,
);

/**
 * Fetch the users that corresponds to the given arguments.
 * @param {String} searchCriteria The search word
 * @param {Boolean} searchIdOnly Set to true to only search for ID
 * @param {Boolean} searchNameOnly Set to true to only search for name
 * @param {Boolean} activeUsersOnly Set to true to only search for active users
 * @param {Boolean} useExactMatch Set to true to only search for an exact match
 */
export const getUsers = async ({
  searchCriteria,
  searchIdOnly,
  searchNameOnly,
  activeUsersOnly,
  useExactMatch,
},
) => request(process.env.VUE_APP_API_USER_MANAGEMENT, 'get', '/users?'
+ `searchCriteria=${searchCriteria || ''}`
+ `&searchIdOnly=${searchIdOnly || false}`
+ `&searchNameOnly=${searchNameOnly || false}`
+ `&activeUsersOnly=${activeUsersOnly || false}`
+ `&useExactMatch=${useExactMatch || false}`);

/**
 * Fetch the user that correspond to the given arguments.
 * @param {globalUserId}
 * @param {userId}
 * @param {legacyUserId}
 * @param {productCode}
 */
export const getUser = async ({
  globalUserId,
  userId,
  legacyUserId,
  productCode,
},
) => request(process.env.VUE_APP_API_USER_MANAGEMENT, 'get', '/user?'
+ `globalUserId=${globalUserId || ''}`
+ `&userId=${userId || ''}`
+ `&legacyUserId=${legacyUserId || ''}`
+ `&productCode=${productCode || ''}`);

/**
 * Update user.
 * @param {String} globalUserId
 * @param {Array} roles Roles to CREATE / UPDATE / DELETE (operation).
 * @param {Array} preferences
 * @param {String} action VALIDATE / COMMIT
 */
export const putUser = async ({
  globalUserId,
  roles,
  preferences,
  privileges,
  action,
},
) => request(process.env.VUE_APP_API_USER_MANAGEMENT, 'put', '/user', {
  globalUserId,
  ...roles && { roles },
  ...preferences && { preferences },
  ...privileges && { privileges },
  action,
});

/**
 * Fetch the users that has the given product role
 * @param {String} productCode The product code
 * @param {String} roleName The role name
 */
export const getUsersWithRole = async ({
  productCode,
  roleName,
},
) => request(process.env.VUE_APP_API_USER_MANAGEMENT, 'get', '/userswithrole?'
+ `productCode=${productCode || ''}`
+ `&roleName=${roleName}`);
